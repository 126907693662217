<template>
  <div>
    <v-sheet
      tabindex="0"
      color="light-blue lighten-4"
      width="100%"
      height="200"
      class="pa-2"
      outlined
      rounded
      @click="$refs.filesUploadField.click()"
    >
      <input
        type="file"
        id="site-locations-upload"
        class="d-none"
        accept="text/csv,.csv"
        ref="filesUploadField"
      />
      <label for="site-locations-upload" class="d-none" aria-hidden="true"
        >Upload</label
      >
      <v-row justify="center" class="mt-6">
        <v-icon :color="dropIconColor" size="75"
          >mdi-cloud-upload-outline</v-icon
        >
      </v-row>
      <v-row justify="center">
        <span class="title primary--text text--darken-2"
          >Drag and drop or click to upload a file!</span
        >
      </v-row>
      <v-row justify="center">
        <span class="title red--text text--darken-2" v-if="showSingleFileAlert"
          >You can only upload a single file.</span
        >
        <span class="title primary--text text--darken-2" v-if="isProcessing"
          >Processing {{ fileName }}</span
        >
      </v-row>
    </v-sheet>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      color="warning"
      timeout="-1"
      outlined
      absolute
      bottom
      right
      transition="slide-x-reverse-transition"
    >
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "FileDrop",
  data: () => ({
    dragover: false,
    dropIconColor: "primary",
    fileName: "",
    isProcessing: false,
    showSingleFileAlert: false,
    showUploadResults: false,
    showUploadResultSnackbar: false,
    snackbar: false,
  }),
  computed: {
    ...mapState("engagementsSiteLocations", ["error"]),
  },
  mounted() {
    const dropZone = this.$el;
    const fileUpload = this.$refs.filesUploadField;
    // Register all drag & drop event listeners
    dropZone.addEventListener("dragleave", (event) => {
      event.preventDefault();
      if (!this.isProcessing) {
        this.dropIconColor = "primary";
      }
    });
    dropZone.addEventListener("dragover", (event) => {
      event.preventDefault();
      if (!this.isProcessing) {
        this.dropIconColor = "success";
      }
    });
    dropZone.addEventListener("drop", (event) => {
      event.preventDefault();
      if (event.dataTransfer && !this.isProcessing) {
        this.fileSelected(event.dataTransfer.files);
      }
    });
    dropZone.addEventListener("keypress", (event) => {
      event.preventDefault();
      if (event.key === "Enter" && !this.isProcessing) {
        fileUpload.click();
      }
    });
    // register listeners on the file input
    fileUpload.addEventListener("change", (event) => {
      const target = event.target;
      if (target.files && !this.isProcessing) {
        this.fileSelected(target.files);
      }
    });
  },
  methods: {
    ...mapActions("engagementsSiteLocations", ["uploadTrialSites"]),
    async fileSelected(fileList) {
      if (fileList.length > 1) {
        this.showSingleFileAlert = true;
        this.dropIconColor = "red";

        return false;
      }
      this.dropIconColor = "orange";
      this.fileName = fileList[0].name;
      this.$emit("updateFileName", this.fileName);
      this.showSingleFileAlert = false;
      this.isProcessing = true;
      const formData = new FormData();
      formData.append("trial_sites_file", fileList[0]);
      formData.append("engagement_id", this.engagementId);

      await this.uploadTrialSites({
        formData: formData,
        engagementId: this.engagementId,
      }).then((success) => {
        this.$refs.filesUploadField.value = null;
        if (success) {
          this.showUploadResults = true;
        } else {
          this.snackbar = true;
        }
      });

      this.isProcessing = false;
    },
  },
  props: {
    engagementId: {
      String,
    },
  },
};
</script>

<style scoped></style>
