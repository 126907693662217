<template>
  <v-row>
    <v-col cols="12" sm="8" class="mx-auto">
      <v-card>
        <v-card-text>
          <h3>
            {{ fileName }}: {{ uploadResult.total }}
            <template v-if="uploadResult.total > 1">Records</template>
            <template v-else>Record</template>
            Processed.
          </h3>
          <ul id="result-counts">
            <li>
              {{ uploadResult.created }} new trial sites were created & added.
            </li>
            <li>
              {{ uploadResult.existing }} existing trial sites were added.
            </li>
            <li>
              {{ uploadResult.duplicates }} duplicate trial sites were ignored.
            </li>
            <li>
              {{ uploadResult.failed }} new trial sites were not added & require
              attention.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card v-if="uploadResult.errors?.rows?.length > 0">
        <section-header :is-collapsable="false">
          <v-container>
            <v-row>
              <v-col cols="12" md="4"> Invalid Trial Sites </v-col>
              <v-spacer></v-spacer>
              <v-btn class="mx-1 primary" @click="continueWithoutSaving">
                Continue without Fixing
              </v-btn>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="uploadResult.errors.rows"
            item-key="id"
            :footer-props="footerProps"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-dialog
                    persistent
                    transition="dialog-bottom-transition"
                    max-width="75%"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="updateActiveSiteLocation(item.attributes)"
                        small
                        color="primary"
                        >mdi-pencil</v-icon
                      >
                    </template>

                    <template v-slot:default="dialog">
                      <v-card>
                        <section-header class="pl-3">
                          Editing Row Number {{ item.id }}
                        </section-header>
                        <v-card-text>
                          <site-locations-form
                            :siteLocation="siteLocation"
                            ref="siteLocationForm"
                          ></site-locations-form>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            class="mx-1 cancel-btn"
                            @click="dialog.value = false"
                          >
                            Cancel
                            <v-icon aria-label="Cancel Icon">mdi-cancel</v-icon>
                          </v-btn>
                          <v-btn
                            class="mx-1 primary"
                            type="submit"
                            @click="submit(dialog, item)"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  {{ item.id }}
                </td>
                <td :class="hasError('piName', item)">
                  {{ item.attributes.piName }}
                </td>
                <td :class="hasError('siteId', item)">
                  {{ item.attributes.siteId }}
                </td>
                <td :class="hasError('name', item)">
                  {{ item.attributes.name }}
                </td>
                <td :class="hasError('siteLocationType', item)">
                  {{ item.attributes.siteLocationType }}
                </td>
                <td :class="hasError('siteLocationIrbType', item)">
                  {{ item.attributes.siteLocationIrbType }}
                </td>
                <td :class="hasError('streetAddress', item)">
                  {{ item.attributes.streetAddress }}
                </td>
                <td :class="hasError('city', item)">
                  {{ item.attributes.city }}
                </td>
                <td :class="hasError('state', item)">
                  {{ item.attributes.state }}
                </td>
                <td :class="hasError('zipCode', item)">
                  {{ item.attributes.zipCode }}
                </td>
                <td :class="hasError('country', item)">
                  {{ item.attributes.country }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-snackbar
            v-model="snackbar"
            :multi-line="true"
            color="warning"
            timeout="-1"
            outlined
            absolute
            bottom
            right
            transition="slide-x-reverse-transition"
          >
            {{ error }}

            <template v-slot:action="{ attrs }">
              <v-btn
                icon
                color="warning"
                v-bind="attrs"
                @click="snackbar = false"
              >
                <v-icon>mdi-close</v-icon>
                <span class="d-none" aria-hidden="true">Close</span>
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SectionHeader from "@admin/components/SectionHeader.vue";
import SiteLocationsForm from "@admin/components/site_locations/Form.vue";
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "UploadResults",
  components: { SiteLocationsForm, SectionHeader },
  computed: {
    ...mapFields("siteLocations", ["siteLocation"]),
    ...mapState("countries", ["countries"]),
    ...mapState("siteLocations", [
      "siteLocationTypes",
      "siteLocationIrbTypes",
      "error",
    ]),
    ...mapState("engagements", ["engagement"]),
    ...mapState(["footerProps"]),
  },
  props: {
    fileName: String,
    uploadResult: Object,
    engagementId: {
      String,
    },
  },
  data() {
    return {
      headers: [
        { text: "Row No.", value: "id" },
        { text: "PI Name", value: "attributes.piName" },
        { text: "Site Id", value: "attributes.siteId" },
        { text: "Site Name", value: "attributes.name" },
        { text: "Site Type", value: "attributes.siteLocationType" },
        { text: "IRB", value: "attributes.siteLocationIrbType" },
        { text: "Address", value: "attributes.streetAddress" },
        { text: "City", value: "attributes.city" },
        { text: "State", value: "attributes.state" },
        { text: "Zip", value: "attributes.zipCode" },
        { text: "Country", value: "attributes.country" },
      ],
      showUploadResultSnackbar: false,
      snackbar: false,
      engagementPath: "/engagements/view/" + this.engagementId,
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions("engagementsSiteLocations", [
      "uploadTrialSites",
      "clearEngagementsSiteLocationsUploadResult",
      "addTrialSiteFromUpload",
      "associateEngagementSiteLocation",
    ]),

    ...mapActions("siteLocations", [
      "clearSiteLocation",
      "addSiteLocation",
      "getSiteLocation",
    ]),
    ...mapActions("countries", ["getCountries"]),

    hasError(field, item) {
      return {
        hasError: item.validationErrors.some((error) =>
          Object.prototype.hasOwnProperty.call(error, field)
        ),
      };
    },
    //we need to adjust the csv site location's data to be compatible with the Site Location Form
    updateActiveSiteLocation(item) {
      //update site location type to use value instead of title/text of site location type found in csv
      this.siteLocationTypes.forEach(function (type) {
        if (type.text == item.siteLocationType) {
          item.siteLocationType = type.value;
        }
      });
      //update site location irb type to use value instead of title/text of site location irb type found in csv
      this.siteLocationIrbTypes.forEach(function (irbType) {
        if (irbType.text == item.siteLocationIrbType) {
          item.siteLocationIrbType = irbType.value;
        }
      });
      //update country to use value instead of title/text of site location country found in csv
      this.countries.forEach(function (country) {
        if (country.isoCode == item.country) {
          item.country = country.value;
        }
      });

      //make isActive as default status
      item.isActive = true;
      //if description is blank, then use name as description
      item.description = item.description == "" ? item.name : item.description;

      this.siteLocation = item;
    },
    //ignore remaining invalid items and go back to engagement view page
    continueWithoutSaving() {
      //clear uppload results
      this.clearEngagementsSiteLocationsUploadResult();
      this.$router.push({ path: this.engagementPath });
    },
    async submit(dialog, siteLocation) {
      const formValid = await this.$refs.siteLocationForm.validateForm();
      if (this.isSubmitting || !formValid) return;

      let success = false;
      this.isSubmitting = true;

      success = await this.addSiteLocation();

      if (success !== false) {
        let siteLocationId = success;
        //associate site location and engagement
        const response = await this.associateEngagementSiteLocation({
          engagementId: this.engagementId,
          siteLocationId: siteLocationId,
          radius: this.engagement.radius,
          rmsSiteIdentification: siteLocation.attributes.siteId,
        });

        if (response !== false) {
          this.clearSiteLocation();
          //remove siteLocation for upload result errors
          const index = this.uploadResult.errors.rows.indexOf(siteLocation);
          this.uploadResult.errors.rows.splice(index, 1);
          //decrement from failed
          this.uploadResult.failed--;
          //increment created
          this.uploadResult.created++;
          dialog.value = false;
          this.snackbar = false;
        } else {
          dialog.value = false;
          this.snackbar = !!this.error;
        }
      } else {
        dialog.value = false;
        this.snackbar = !!this.error;
      }

      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    await this.getCountries();
  },
};
</script>

<style scoped>
h3 {
  margin: 0 auto;
}
.cancel-btn {
  background-color: #faaf1c !important;
  color: black;
}
.hasError {
  background-color: #faaf1c;
  border: 3px dashed red;
  min-width: 100%;
}
#result-counts {
  padding: 10px;
}
#result-counts li {
  border-top: 1px solid #aaa;
  color: black;
  list-style-type: none;
  padding: 10px;
}
#result-counts li:before {
  float: left;
  font-size: 3rem;
  margin: 0.05em 0 0 -0.5em;
  width: 0.25em;
  content: "\2022";
}
#result-counts li:nth-child(1):before {
  color: green;
}
#result-counts li:nth-child(2):before {
  color: blue;
}
#result-counts li:nth-child(3):before {
  color: firebrick;
}
#result-counts li:nth-child(4):before {
  color: red;
}
#result-counts li:nth-child(4) {
  border-bottom: 1px solid #aaa;
}
</style>
