<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="8" class="mx-auto">
              <file-drop
                :engagement-id="engagementId"
                v-on:updateFileName="setFileName"
              >
                ></file-drop
              >
            </v-col>
          </v-row>
          <upload-results
            v-if="uploadResult !== undefined"
            :fileName="fileName"
            :engagement-id="engagementId"
            :uploadResult="uploadResult"
          ></upload-results>
        </v-container>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FileDrop from "@admin/components/site_locations/FileDrop.vue";
import UploadResults from "@admin/components/site_locations/UploadResults.vue";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Upload",
  components: { UploadResults, FileDrop },
  computed: {
    ...mapFields("engagements", {
      engagementName: "engagement.name",
      sponsorId: "engagement.sponsor_id",
      sponsorName: "engagement.sponsorName",
    }),
    ...mapState("engagementsSiteLocations", ["uploadResult"]),
  },
  data() {
    return {
      engagementId: this.$route.params.engagementId,
      fileName: "",
    };
  },
  methods: {
    ...mapActions("engagements", ["getEngagement"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    setFileName(fileName) {
      this.fileName = fileName;
    },
  },
  async mounted() {
    await this.getEngagement(this.engagementId);
    this.setBreadcrumbs({
      engagement: {
        id: this.engagementId,
        name: this.engagementName,
      },
      sponsor: {
        id: this.sponsorId,
        name: this.sponsorName,
      },
    });
    this.clearActionButtons();
    this.setTitleObject("Clinical Trial Sites");
  },
};
</script>
